/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Unsubscribe } from '@monterosa-sdk/util';

import { Experience } from './types';
import { onSdkMessage, Action } from './utils/bridge';

/**
 * @internal
 */
export function onResize(
  experience: Experience,
  container: HTMLElement,
): Unsubscribe {
  if (!experience.config.autoresizesHeight) {
    return () => {};
  }

  return onSdkMessage(experience, ({ action, payload }) => {
    if (action === Action.OnResize) {
      /**
       * Container width is maintained the same as it is defined in the parent
       * page to preserve its behaviour. Only the height is updated
       */
      // container.style.width = `${payload.width}px`;
      container.style.height = `${payload.height}px`;
    }
  });
}

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Project } from '@monterosa-sdk/core';
import { fetchListings } from '@monterosa-sdk/util';
import { v4 as uuidv4 } from 'uuid';

import { QueryParam, Experience, ExperienceConfiguration } from './types';
import { getBridge, Bridge } from './utils/bridge';

/**
 * @internal
 */
export default class ExperienceImpl implements Experience {
  readonly _config: Readonly<ExperienceConfiguration>;
  private _parameters: { [key: string]: string } = {};
  readonly bridge: Bridge;
  embedUrl!: string;

  constructor(public project: Project, config: ExperienceConfiguration) {
    this._config = config;
    this.bridge = getBridge(uuidv4());

    if (config.parameters !== undefined) {
      const reserved = Object.values(QueryParam) as string[];
      const ignored: string[] = [];

      Object.entries(config.parameters).forEach(([key, value]) => {
        if (reserved.includes(key)) {
          ignored.push(key);
        } else {
          this._parameters[key] = value;
        }
      });

      if (ignored.length === 1) {
        console.warn(
          `Parameter "${ignored[0]}" ignored as it matches reserved words`,
        );
      }

      if (ignored.length > 1) {
        console.warn(
          `Parameters "${ignored.join(
            '", "',
          )}" ignored as they match reserved words`,
        );
      }
    }
  }

  get config() {
    return Object.assign(this._config, {
      autoresizesHeight: this._config.autoresizesHeight ?? false,
      hidesHeadersAndFooters: this._config.hidesHeadersAndFooters ?? true,
      supportsLoadingState: this._config.supportsLoadingState ?? true,
      experienceUrl: this._config.experienceUrl ?? null,
    });
  }

  async getEmbedUrl() {
    if (this.embedUrl) {
      return Promise.resolve(this.embedUrl);
    }

    const {
      id: projectId,
      sdk: { host },
    } = this.project;

    const listings = await fetchListings(host, projectId);

    this.embedUrl = listings.project.embed;

    return this.embedUrl;
  }

  async getUrl(): Promise<string> {
    const embedUrl = await this.getEmbedUrl();

    // sanitise and create an object from an url
    let url = new URL(ExperienceImpl.sanitiseUrl(embedUrl));

    // override host and project with those that are set in SDK instance
    url.searchParams.set(QueryParam.Host, this.project.sdk.host);
    url.searchParams.set(QueryParam.Project, this.project.id);

    // if custom experienceUrl is set:
    // * take experienceUrl as the base
    // * search params from experienceUrl have priority
    if (this.config.experienceUrl !== null) {
      // sanitise and create an object from a custom url
      const customUrl = new URL(
        ExperienceImpl.sanitiseUrl(this.config.experienceUrl),
      );

      // apply embedUrl query parameters if they don't exist in experienceUrl
      Array.from(url.searchParams.entries())
        .filter(([key]) => !customUrl.searchParams.has(key))
        .forEach(([key, value]) => customUrl.searchParams.set(key, value));

      url = customUrl;
    }

    // Prepare a final list of query parameters
    const queryParameters = {
      ...(this.config.host !== undefined && {
        [QueryParam.Host]: this.config.host,
      }),

      ...(this.config.projectId !== undefined && {
        [QueryParam.Project]: this.config.projectId,
      }),

      ...(this.config.eventId !== undefined && {
        [QueryParam.Event]: this.config.eventId,
      }),

      [QueryParam.BridgeId]: this.bridge.id,

      [QueryParam.HideHeaderAndFooter]: this.config.hidesHeadersAndFooters
        ? '1'
        : '0',

      [QueryParam.AutoresizesHeight]: this.config.autoresizesHeight ? '1' : '0',

      // Adding user defined parameters to the URL search parameters if exists
      ...this._parameters,
    };

    Object.entries(queryParameters).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    return url.href;
  }

  private static sanitiseUrl(
    url: string,
    options?: {
      stripReservedParameters?: 'all' | 'mic';
    },
  ): string {
    const stripReservedParameters = options?.stripReservedParameters || 'mic';

    // Ensure the URL includes a protocol.
    if (/^http/.test(url) === false) {
      // If none are found, use the same as the document.
      url = `${document.location.protocol}${url}`;
    }

    const urlObj = new URL(url);

    Object.values(QueryParam)
      .filter((key) => {
        if (stripReservedParameters === 'mic') {
          return key.startsWith('mic');
        }

        return key;
      })
      .forEach((key) => urlObj.searchParams.delete(key));

    return urlObj.href;
  }
}

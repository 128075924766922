/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Bridge } from './utils/bridge';
import { ParentApplication } from './types';

class ParentApplicationImpl implements ParentApplication {
  constructor(public bridge: Bridge) {}
}

export default ParentApplicationImpl;

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

/* eslint no-console: "off" */

import { EventEmitter } from 'events';
import { getGlobal } from '@monterosa-sdk/util';

import { isMessage } from './helpers';

const globals = getGlobal();

const receiveMessage = (message: any) => {
  if (!isMessage(message)) {
    // data does not match message format
    return;
  }

  globals.monterosaSdk!.emitter.emit('message', message);
};

function handleWindowMessage({ data }: MessageEvent) {
  try {
    if (typeof data !== 'string') {
      // ignore non string data
      return;
    }

    const message = JSON.parse(data);

    receiveMessage(message);
  } catch (err) {
    if (err instanceof Error) {
      console.error(err.message);
    }
  }
}

/**
 * Since this code operates as a side effect while updating the global space,
 * we must be very careful because it may run twice, for example, when two apps
 * using the SDK run on the same page. Additionally, the global namespace may
 * already be updated by the Android or iOS SDKs.
 */

// The Monterosa SDK namespace may already exist, either because it was created
// by a native SDK or by another web app using the JS SDK from the same scope.
globals.monterosaSdk ??= {
  initialised: false,
  emitter: new EventEmitter(),
  receiveMessage,
};

// Each of nullish coalescing assignments will only be applied when
// the Monterosa SDK namespace exists at that moment and one of its properties
// is not defined.
globals.monterosaSdk.initialised ??= false;
globals.monterosaSdk.emitter ??= new EventEmitter();
globals.monterosaSdk.receiveMessage ??= receiveMessage;

if (!globals.monterosaSdk.initialised) {
  // Subscribe to the message only once for each app that uses the SDK on this page.
  if (typeof globals.addEventListener !== 'undefined') {
    globals.addEventListener('message', handleWindowMessage);
  }

  globals.monterosaSdk.initialised = true;
}

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

/**
 * Monterosa SDK / Launcher Kit
 *
 * @packageDocumentation
 */

import { registerEmbedHook } from './api';
import { onResize } from './bridge';

registerEmbedHook(onResize);

export * from './api';
export * from './types';
export * from './utils/bridge';
export * from './child_helpers';
export * from './parent_application';
export * from './storage';

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Project as MonterosaProject } from '@monterosa-sdk/core';
import { Unsubscribe } from '@monterosa-sdk/util';

import { Bridged } from './utils/bridge/public-types';

/**
 * Reserved query parameter names for controlling the behaviour of Experience.
 */
export enum QueryParam {
  /**
   * Represents the host of the application. This parameter is added by Studio
   * and the embed URL already contains it.
   */
  Host = 'h',
  /**
   * Represents the Project Id of the application. This parameter is added by
   * Studio and the embed URL already contains it.
   */
  Project = 'p',
  /**
   * Represents the Event Id of the application. This parameter is added to the
   * experience URL when the eventId is provided in the experience config.
   */
  Event = 'e',
  /**
   * Used to identify the unique ID of the communication bridge between
   * the parent application and the child experience.
   */
  BridgeId = 'micBridgeId',
  /**
   * Determines whether the header and footer views of the application should be
   * hidden. This parameter can be used to control the visibility of the
   * application's header and footer components.
   */
  HideHeaderAndFooter = 'micHideHeaderAndFooter',
  /**
   * Determines whether autoresize is enabled for the application. When autoresize
   * is enabled, the application's height will adjust automatically based on its
   * content.
   */
  AutoresizesHeight = 'micAutoresizesHeight',
}

/**
 * Interface of user Experience provided by the Monterosa / Interaction Cloud
 */
export interface Experience extends Bridged {
  /**
   * Instance of Project
   *
   * @internal
   */
  project: MonterosaProject;
  /**
   * Config
   */
  config: ExperienceConfiguration;
  /**
   * Generates the URL to use when embedding the experience by
   * reconciling multiple data sources:
   *
   * 1. The embed URL as specified in App spec and obtained via Listings
   *
   * 2. The `host` from SDK instance and `projectId` from the Project instance
   *
   * 3. The `experienceUrl` passed via {@link ExperienceConfiguration}
   *
   * 4. `host`, `projectId` and `eventId` parameters passed via {@link ExperienceConfiguration}
   *
   * 5. Custom `parameters` dictionary passed via {@link ExperienceConfiguration}
   *
   * To reconcile all of those data sources, the SDK . Also we protect
   * {@link QueryParam | reserved parameters} required for the functioning
   * of the SDK.
   *
   * This implies:
   *
   * - SDK will always prioritise using the values lower in the list above.
   *
   * - SDK {@link QueryParam | reserved parameters} will be stripped out from
   *   embed URL, `experienceURL` and custom
   *   {@link ExperienceConfiguration | parameters dictionary}.
   *
   * - `h`, `e`, `p` query parameters provided in custom parameters dictionary
   *   will be ignored
   *
   * @returns the URL to use when embedding the Experience.
   */
  getUrl(): Promise<string>;
}

/**
 * Interface of parent application
 *
 * @remarks
 * Parent application is a reference to the parent window where Experience
 * resides. It is used to establish communication bridge between Experience and
 * parent application. It can be get with {@link getParentApplication}
 */
export interface ParentApplication extends Bridged {}

/**
 * Experience configuration options
 */
export interface ExperienceConfiguration {
  /**
   * The user is able to override default experience URL. If its not empty,
   * default experience URL will be overwritten with the provided one. At the
   * same time URL query parameters will remain.
   */
  experienceUrl?: string | null;
  /**
   * Overrides the host portion of the resulting experience URL. This property
   * takes precedence over `experienceUrl`. For example, if `experienceUrl` is
   * "https://example.com?h=h1" and the `host` is set to "h2", the resulting URL
   * will be: https://example.com?h=h2
   */
  host?: string;
  /**
   * Overrides the project id portion of the resulting experience URL. This
   * property takes precedence over `experienceUrl`. For example,
   * if `experienceUrl` is "https://example.com?p=p1" and the `projectId` is
   * set to "p2", the resulting URL will be: https://example.com?p=p2
   */
  projectId?: string;
  /**
   * If event id is provided, Experience can automatically displays elements
   * associated with this event. It is worth to mention that the behaviour of
   * Experiences depend on their implementation and can vary.
   *
   * This property takes precedence over `experienceUrl`. For example, if
   * `experienceUrl` is "https://example.com?e=e1" and the `eventId` is set to
   * "e2", the resulting URL will be: https://example.com?e=e2
   */
  eventId?: string;
  /**
   * A key/value dictionary of custom parameters that will be passed on to the
   * embedded Experience as URL query parameter. There are several reserved
   * parameter keys: "p", "h", "e", "micHideHeaderAndFooter" and "micBridgeId".
   * When one of reserved keys is provided it will be ignored.
   */
  parameters?: { [key: string]: string };
  /**
   * If true the container adjusts its height according to the intrinsic
   * height of embedded Experience
   */
  autoresizesHeight?: boolean;
  /**
   * If true removes the headers and footers of embedded Experience,
   * so that the resulting UI does not display two headers/footers.
   */
  hidesHeadersAndFooters?: boolean;
  /**
   * The user is able to control wether loading state is enabled or disabled
   * using this property. By default, it is true meaning that the loading state
   * will be enabled.
   */
  supportsLoadingState?: boolean;
  /**
   * Loading template is a function that returns html markup which can be used
   * to substitute default loader UI. Make sure that the template is wrapped in
   * a single html element, e.g. `() => '<div>...loading...</div>'`
   */
  loadingTemplate?: () => string;
  /**
   * Specifies the maximum timeout delay, in milliseconds, for loading the
   * Experience. After this period, the Experience is considered to be loaded,
   * and the loading view will be hidden, even if the loading process has not
   * been fully completed. The default value is 5000 milliseconds.
   */
  dismissLoadingViewTimeout?: number;
  /*
   * Defines a Permissions Policy for the <iframe>. This policy outlines the
   * available features for the <iframe> (e.g., access to the microphone,
   * camera, battery, web-share, etc.) based on the origin of the request.
   *
   * Additional information can be found at: https://developer.mozilla.org/en-US/docs/Web/HTTP/Permissions_Policy
   */
  allow?: string;
  /**
   * Set this to true to enable iframe's fullscreen mode compatibility with
   * older browsers.
   */
  allowFullScreen?: boolean;
}

/**
 * @internal
 */
export interface Integration {
  container: HTMLElement;
  experience: Experience;
  abortController: AbortController;
  resizeUnsubscribe?: Unsubscribe;
  storageUnsubscribe?: Unsubscribe;
  hookUnsubscribers: Unsubscribe[];
}

/**
 * @internal
 */
export type EmbedHook = (
  experience: Experience,
  container: HTMLElement,
) => Unsubscribe;

/**
 * Representing different modes of embedding for an Experience.
 */
export enum EmbedMode {
  Sdk = 'sdk',
  IFramed = 'iframed',
  Standalone = 'standalone',
}

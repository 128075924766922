/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Unsubscribe, throttle } from '@monterosa-sdk/util';

import { RESIZE_THROTTLE_TIMEOUT } from './constants';

import { QueryParam, Experience } from './types';

import { getParentApplication } from './parent_application';
import {
  sendSdkMessage,
  sendSdkRequest,
  onSdkMessage,
  Action,
} from './utils/bridge';

function getUrlParam(param: string) {
  let queryString = {};

  if (typeof window !== 'undefined') {
    queryString = window.location.search;
  }

  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(param);
}

/**
 * The SDK on the parent application will by default request a child
 * Experience to hide its header and footer by setting a query parameter called
 * `micHideHeaderAndFooter` to `1`.
 *
 * This function will return true if that is the case, and false otherwise.
 *
 * When true, the developer of a child Experience is expected to hide it's headers
 * and footers as the parent application is actively providing them.
 *
 * You can test whether you are respecting this correctly by adding the following query
 * to your URL:
 *
 * "?micHideHeaderAndFooter=1"
 *
 * @returns Whether the header and footer views should be hidden.
 */
export function shouldHideHeaderAndFooter() {
  return getUrlParam(QueryParam.HideHeaderAndFooter) === '1';
}

/**
 * This function will returns true if experience is embedded in autoresize height mode.
 */
export function isAutoresizesHeight() {
  return getUrlParam(QueryParam.AutoresizesHeight) === '1';
}

/**
 * This function is used to notify the parent application that the Experience
 * is ready to be used. It's intended to be called by the Experience itself and
 * not by the parent application.
 *
 * @example
 * ```javascript
 * import { sendReady } from '@monterosa-sdk/launcher-kit';
 *
 * sendReady();
 * ```
 *
 * @returns void
 */
export function sendReady(): void {
  const parentApp = getParentApplication();

  if (parentApp === null) {
    console.log(
      'Unable to send finished loading UI message, as there is no parent application',
    );

    return;
  }

  sendSdkRequest(parentApp, Action.OnReady);
}

/**
 * Sends a message to the SDK of the parent application informing it that
 * the UI of this Experience has completed loading.
 *
 * You should call this method when your UI has completed loading and you are ready to
 * display data to the user. This can be when your network calls have successfully requested
 * data and you have updated the DOM for it, or when an error has occurred and you want
 * to take over the UI to provide details to the user.
 *
 * The SDK will use this message to dismiss the loading indicator, allowing a seamless
 * transition between a native loading UI, into a fully loaded, child Experience.
 *
 * If this call is not made, the parent SDK will add a grace period to ensure the web app
 * has sufficient time to load, and eventually dismiss the loading state, whether Experience
 * is ready or not, so as to avoid scenarios were a defect leaves an infinite loading state
 * presented to the user.
 */
export function sendFinishedLoadingUI() {
  const parentApp = getParentApplication();

  if (parentApp === null) {
    console.log(
      'Unable to send finished loading UI message, as there is no parent application',
    );

    return;
  }

  sendSdkMessage(parentApp, Action.OnUILoaded);
}

/**
 * Adds an observer for when more data is requested by the parent application
 *
 * @param callback - The callback that is triggered when a request for more data
 * is received
 * @returns The unsubscribe function. When it's called,
 * the observer will be removed and requests for data will no longer be received
 */
export function onMoreDataRequested(callback: () => void): Unsubscribe {
  const parentApp = getParentApplication();

  if (parentApp === null) {
    console.log(
      'Unable to subscribe to more data event, as there is no parent application',
    );

    return () => {};
  }

  return onSdkMessage(parentApp, ({ action }) => {
    if (action === Action.OnMoreDataRequested) {
      callback();
    }
  });
}

/**
 * Adds an observer for when experience is fully embedded and ready to accept
 * incoming messages
 *
 * @param experience - Experience instance
 * @param callback - The callback that is triggered when experience is embedded
 * @returns The unsubscribe function. When it's called, the observer will be
 * removed and ready event will not be received
 */
export function onReady(
  experience: Experience,
  callback: () => void,
): Unsubscribe {
  return onSdkMessage(experience, ({ action }) => {
    if (action === Action.OnReady) {
      callback();
    }
  });
}

/**
 * @internal
 */
export const sendExperienceSizeThrottled: (
  width: number,
  height: number,
) => void = throttle(
  (width: number, height: number) => {
    const parentApp = getParentApplication();

    if (parentApp === null) return;

    sendSdkMessage(parentApp, Action.OnResize, { width, height });
  },
  RESIZE_THROTTLE_TIMEOUT,
  {
    leading: true,
    trailing: true,
  },
);

/**
 * @internal
 */
export function sendExperienceSize(width: number, height: number) {
  return sendExperienceSizeThrottled(width, height);
}

/**
 * Sets up an observer to watch for the element dimensions change and
 * automatically reports their changes to the parent application.
 *
 * @param element - HTML element whose dimensions need to be watched
 * @returns The unsubscribe function. When it's called,
 * the observer will be removed and element dimensions are no longer tracked
 */
export function reportExperienceSizeChanges(element: HTMLElement): () => void {
  const observer = new ResizeObserver(() => {
    const width = Math.max(element.offsetWidth, element.scrollWidth);
    const height = Math.max(element.offsetHeight, element.scrollHeight);

    sendExperienceSize(width, height);
  });

  observer.observe(element);

  return () => observer.unobserve(element);
}

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import {
  checkAvailability,
  getItem,
  setItem,
  removeItem,
  clear,
} from '@monterosa-sdk/util';

export default class StorageImpl {
  private memoryStore: { [key: string]: string } = {};
  private accessible: boolean = checkAvailability();
  private _persistent: boolean = false;

  set persistent(newValue: boolean) {
    const oldValue = this._persistent;

    if (oldValue === newValue) {
      return;
    }

    const swapToStorage = newValue === true && this.accessible;
    const swapToMemory = newValue === false && this.accessible;

    if (swapToStorage) {
      for (const [key, value] of Object.entries(this.memoryStore)) {
        setItem(key, value);
      }
    }

    if (swapToMemory) {
      clear();
    }

    this._persistent = newValue;
  }

  get persistent(): boolean {
    return this._persistent;
  }

  getItem(key: string): string | null {
    if (this.persistent && this.accessible) {
      return getItem(key);
    }

    if (Object.prototype.hasOwnProperty.call(this.memoryStore, key)) {
      return this.memoryStore[key];
    }

    return null;
  }

  setItem(key: string, value: string): void {
    if (this.persistent && this.accessible) {
      setItem(key, value);
    }

    this.memoryStore[key] = value;
  }

  removeItem(key: string): void {
    if (this.persistent && this.accessible) {
      removeItem(key);
    }

    delete this.memoryStore[key];
  }

  clear(): void {
    if (this.persistent && this.accessible) {
      clear();
    }

    this.memoryStore = {};
  }
}

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Project, LogLevel } from '@monterosa-sdk/core';
import { Unsubscribe, delay } from '@monterosa-sdk/util';

import {
  Experience,
  ExperienceConfiguration,
  Integration,
  EmbedHook,
  EmbedMode,
} from './types';

import { LOADER_MIN_TIMEOUT, LOADER_MAX_TIMEOUT } from './constants';

import { logger } from './logger';

import {
  hasParentBridge,
  sendSdkMessage,
  onSdkMessage,
  Action,
} from './utils/bridge';

import ExperienceImpl from './experience_impl';

import { show as showLoader, hide as hideLoader } from './loader';

import { version } from '../package.json';

/**
 * The current SDK version.
 *
 * @internal
 */
export const VERSION = version;

const integrations: Map<HTMLElement, Integration> = new Map();
const embedHooks: EmbedHook[] = [];

/**
 * Creates an iframe with the provided parameters.
 *
 * @param url Url of the web app.
 *
 * @private
 */
function createIFrame(
  url: string,
  options: {
    id?: string;
    scrolling?: boolean;
    allow?: string;
    allowFullScreen?: boolean;
  } = {},
): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.border = '0';
  iframe.style.display = 'block';
  iframe.style.boxSizing = 'border-box';

  iframe.setAttribute('src', url);

  if (options.id !== undefined) {
    iframe.setAttribute('id', options.id);
  }

  if (options.allow !== undefined) {
    iframe.setAttribute('allow', options.allow);
  }

  if (options.allowFullScreen === true) {
    iframe.setAttribute('allowfullscreen', '');
  }

  /**
   * Though scrolling attribute is deprecated we still have to rely on it as some
   * browsers (e.g. Chrome, as of now version 103) just ignores overflow: hidden.
   * Hence we are setting scrolling to no in order to eliminate scroll and calculate
   * width of the child Experience correctly. If scroll persists it appears on each
   * content change what leads to incorrect width calculation.
   *
   * Later we will look into alternatives such as iframeless Experience embed
   */
  if (!options.scrolling) {
    iframe.style.overflow = 'hidden';
    iframe.setAttribute('scrolling', 'no');
  }

  return iframe;
}

function concealIFrame(iframe: HTMLIFrameElement) {
  iframe.style.opacity = '0';
}

function revealIFrame(iframe: HTMLIFrameElement) {
  iframe.style.opacity = '1';
}

/**
 * Returns Experience for the given project.
 *
 * @param project - The project instance.
 * @param config - The configuration object for the experience (optional).
 *
 * @returns An instance of the Experience.
 */
export function getExperience(
  project: Project,
  config: ExperienceConfiguration = {},
): Experience {
  const experience = new ExperienceImpl(project, config);

  return experience;
}

function onUILoaded(experience: Experience): Promise<void> {
  return new Promise((resolve) => {
    const unsubscribe = onSdkMessage(experience, ({ action }) => {
      if (action === Action.OnUILoaded) {
        unsubscribe();
        resolve();
      }
    });
  });
}

async function experienceReady(experience: Experience) {
  /**
   * Minimum delay during which the Experience is considered loaded,
   * even if it loaded earlier than the timer reaching the minimum timeout.
   */
  const minTimeout = delay(LOADER_MIN_TIMEOUT);

  /**
   * Final cutoff timeout delay, after which the Experience
   * is considered loaded even if it is still in the process of loading.
   */
  const maxTimeout = delay(LOADER_MAX_TIMEOUT);

  /**
   * Promisifying OnUILoaded communication bridge event
   */
  const uiLoaded = onUILoaded(experience);

  const hasFullyLoadedExperience = Promise.all([minTimeout, uiLoaded]);

  const eitherTimeoutOrFullyLoaded = Promise.race([
    maxTimeout,
    hasFullyLoadedExperience,
  ]);

  return eitherTimeoutOrFullyLoaded;
}

async function showLoadingState(integration: Integration): Promise<void> {
  const { abortController, experience, container } = integration;

  if (abortController.signal.aborted) {
    return;
  }

  if (!experience.config.supportsLoadingState) {
    return;
  }

  await showLoader(container, experience.config.loadingTemplate);
}

async function hideLoadingState(integration: Integration): Promise<void> {
  const { abortController, experience, container } = integration;

  if (abortController.signal.aborted) {
    return;
  }

  if (!experience.config.supportsLoadingState) {
    return;
  }

  await hideLoader(container);
}

function resolveContainer(containerOrId: HTMLElement | string) {
  const container =
    containerOrId instanceof HTMLElement
      ? containerOrId
      : document.getElementById(containerOrId);

  if (container === null) {
    throw new Error(
      `The container element with the ID "${containerOrId}" does not exist in the DOM.`,
    );
  }

  return container;
}

function clearContainer(container: HTMLElement) {
  while (container.lastElementChild) {
    container.removeChild(container.lastElementChild);
  }

  ['height', 'display', 'alignItems', 'justifyContent'].forEach((attr) => {
    container.style.setProperty(attr, '');
  });
}

function createIntegration(
  experience: Experience,
  container: HTMLElement,
): Integration {
  const abortController = new AbortController();
  const hookUnsubscribers: Unsubscribe[] = embedHooks.map((hook) =>
    hook(experience, container),
  ); // eslint-disable-line

  const integration: Integration = {
    container,
    experience,
    abortController,
    hookUnsubscribers,
  };

  integrations.set(container, integration);

  return integration;
}

function teardownIntegration(container: HTMLElement): void {
  const integration = integrations.get(container);

  if (!integration) {
    return;
  }

  integration.abortController.abort();

  for (const unsub of integration.hookUnsubscribers) {
    unsub();
  }

  integrations.delete(container);
}

async function injectIFrame(
  integration: Integration,
): Promise<HTMLIFrameElement | undefined> {
  const { experience, container } = integration;

  if (integration.abortController.signal.aborted) {
    return undefined;
  }

  const url = await experience.getUrl();

  if (integration.abortController.signal.aborted) {
    return undefined;
  }

  const iframe = createIFrame(url, {
    id: experience.bridge.iFrameId,
    scrolling: !experience.config.autoresizesHeight,
    allow: experience.config.allow,
    allowFullScreen: experience.config.allowFullScreen,
  });

  container.appendChild(iframe);

  return iframe;
}

function showError(container: HTMLElement): void {
  const p = document.createElement('p');
  p.innerText = 'We weren’t able to load the Experience';

  container.style.display = 'flex';
  container.style.alignItems = 'center';
  container.style.justifyContent = 'center';

  container.appendChild(p);
}

/**
 * Embeds web Experience app into iframe. There is only one app can be
 * associated with Experience and it is configured in
 * Monterosa / Interaction Cloud. Please refer the developer guide to get
 * more information on what is app and how to configure it:
 * {@link https://products.monterosa.co/mic/developer-guides/whats-an-app}
 *
 * @example
 * ```javascript
 * const experience = getExperience();
 *
 * embed(experience, 'container-id');
 * ```
 * @param {Experience} - An instance of Experience
 * @param {HTMLElement | string} containerOrId - HTML element instance or
 *     element id where iframe is embedded into.
 *
 * @public
 */
export async function embed(
  experience: Experience,
  containerOrId: HTMLElement | string,
): Promise<void> {
  // 1. Resolve container
  const container = resolveContainer(containerOrId);

  if (integrations.has(container)) {
    throw new Error(
      `The Experience is already embedded in the container with the ID "${containerOrId}".`,
    );
  }

  // 2. Create integration (combination of Experience, abortController and hooks)
  const integration = createIntegration(experience, container);

  try {
    // 3. Show loader
    //
    // Although showLoadingState is an asynchronous function, we execute
    // it synchronously to embed the iframe as quickly as possible.
    showLoadingState(integration);

    // 4. Inject experience iframe
    const iframe = await injectIFrame(integration);

    if (iframe !== undefined) {
      concealIFrame(iframe);
    }

    // 5. Wait until experience is ready
    await experienceReady(experience);

    if (iframe !== undefined) {
      revealIFrame(iframe);
    }

    // 6. Hide loading state
    await hideLoadingState(integration);
  } catch (err) {
    await hideLoadingState(integration);

    if (err instanceof Error) {
      showError(container);
    }

    throw err;
  }
}

/**
 * Unmounts web Experience app which was previously embedded in the container.
 *
 * @example
 * ```javascript
 * unmount('container-id');
 * ```
 *
 * @param {HTMLElement | string} containerOrId - HTML element instance or
 *     element id where iframe is embedded into.
 *
 * @public
 */
export function unmount(containerOrId: HTMLElement | string): void {
  const container = resolveContainer(containerOrId);

  teardownIntegration(container);

  clearContainer(container);
}

/**
 * Informs the Experience that more data should be loaded and displayed on the UI.
 *
 * @remarks
 * One example is when Experience renders items feed partially. Once a user scrolled
 * to the bottom edge of the app, more elements to load is requested.
 *
 * @param experience - Experience instance
 */
export function requestMoreData(experience: Experience): void {
  sendSdkMessage(experience, Action.OnMoreDataRequested);
}

/**
 * @internal
 */
export function registerEmbedHook(hook: EmbedHook) {
  embedHooks.push(hook);
}

/**
 * Enables logging with the specified log level.
 *
 * @param logLevel - The log level or flag to determine the logging
 *   behavior. If a log level is provided, it will be used to set the log
 *   level. If a boolean flag is provided, logging will be enabled or disabled
 *   based on the flag value.
 */
export function enableLogging(logLevel?: LogLevel): void;

/**
 * Enables or disables logging based on the provided flag.
 *
 * @param enable - The flag to determine the logging behavior. If a boolean flag
 *   is provided, logging will be enabled or disabled based on the flag value.
 */
export function enableLogging(enable?: boolean): void;

export function enableLogging(logLevelOrFlag: LogLevel | boolean = true) {
  if (typeof logLevelOrFlag === 'boolean') {
    logger.logLevel = logLevelOrFlag ? LogLevel.Verbose : LogLevel.Silent;

    return;
  }

  logger.logLevel = logLevelOrFlag;
}

/**
 * Determines the embed mode of Experience.
 *
 * @returns The embed mode of Experience.
 */
export function getEmbedMode(): EmbedMode {
  if (hasParentBridge()) {
    return EmbedMode.Sdk;
  }

  if (window.top !== window) {
    return EmbedMode.IFramed;
  }

  return EmbedMode.Standalone;
}

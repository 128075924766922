/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { getGlobal } from '@monterosa-sdk/util';

const globals = getGlobal();

/**
 * @internal
 */
export const IFRAME_ID_PREFIX = 'micBridge';

export const IS_IOS =
  !!globals.webkit?.messageHandlers?.monterosaSdk?.postMessage;
export const IS_ANDROID = !!globals.monterosaSdk?.postMessage;
export const IS_WEB = globals.self !== globals.parent;

/**
 * @internal
 */
export const VERSION = '1.0.0';

/**
 * @license
 * @monterosa-sdk/interact-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Logger } from '@monterosa-sdk/core';

export const logger = new Logger('@monterosa-sdk/launcher-kit');

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Unsubscribe } from '@monterosa-sdk/util';

import { Experience } from './types';

import { registerEmbedHook } from './api';
import { getParentApplication } from './parent_application';
import StorageImpl from './storage_impl';

import {
  sendSdkRequest,
  respondToSdkMessage,
  onSdkMessage,
  Payload,
} from './utils/bridge';

/**
 * @internal
 */
export enum StorageAction {
  Read = 'storageRead',
  Write = 'storageWrite',
  Remove = 'storageRemove',
  Clear = 'storageClear',
}

/**
 * @internal
 */
export const storage = new StorageImpl();

/**
 * @internal
 */
export function listenStorageMessages(experience: Experience): Unsubscribe {
  return onSdkMessage(experience, (message) => {
    let payload: Payload = {};

    if (
      !Object.values(StorageAction).includes(message.action as StorageAction)
    ) {
      return;
    }

    switch (message.action) {
      case StorageAction.Read:
        payload = {
          value: storage.getItem(message.payload.key),
        };
        break;
      case StorageAction.Write:
        storage.setItem(message.payload.key, message.payload.value);
        break;
      case StorageAction.Remove:
        storage.removeItem(message.payload.key);
        break;
      case StorageAction.Clear:
        storage.clear();
        break;
    }

    respondToSdkMessage(experience, message, payload);
  });
}

/**
 * The `setStoragePersistent` function is a simple function that allows to
 * control the persistence of the SDK storage. If the argument `persistent` is
 * set to `true`, then the storage will be persistent across browser sessions
 * and if it is set to `false`, then the storage will save to memory.
 * It is  important to note that the use of persistent storage may be subject
 * to laws and regulations, such as those related to data privacy and protection.
 *
 * @remarks
 * - We transition from persistent to memory and memory to persistent in
 *   a seamless manner for you
 *
 * - By default we store in memory
 *
 * - The value of storage persistent persists across session (aka put it to true,
 *   it will remain so, put it back to false, it will remain so)
 *
 * - You have the responsibility to comply with any laws and regulations, and
 *   store only the data if you know it's valid to do so
 *
 * @param persistent - Determines whether or not SDK storage should persist
 * across browser sessions.
 */
export function setStoragePersistent(persistent: boolean): void {
  storage.persistent = persistent;
}

/**
 * The function allows to read data from the SDK storage.
 *
 * @param key - The name of the item to be read from storage.
 *
 * @throws
 * The function throws an error if there is a timeout reading the data
 * from the parent application storage.
 *
 * @returns A promise that resolves to the value
 * of the item in storage or `null` if the item doesn't exist.
 */
export async function storageRead(key: string): Promise<string | null> {
  const parentApp = getParentApplication();

  let value: string | null = null;

  if (parentApp !== null) {
    const result = await sendSdkRequest(parentApp, StorageAction.Read, {
      key,
    });

    value = result.payload.value as string;
  } else {
    value = storage.getItem(key);
  }

  return Promise.resolve(value);
}

/**
 * The function allows to write data to the SDK storage.
 *
 * @param key - A name of the item to be stored.
 * @param value - A value to be stored.
 *
 * @throws
 * The function throws an error if there is a timeout writing the data
 * to the parent's application storage.
 *
 * @returns A promise that resolves to `void` once the data has
 * been successfully stored.
 */
export async function storageWrite(key: string, value: string): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await sendSdkRequest(parentApp, StorageAction.Write, {
      key,
      value,
    });
  } else {
    storage.setItem(key, value);
  }

  return Promise.resolve();
}

/**
 * The function allows to remove an item from the SDK storage.
 *
 * @param key - A name of the item to be removed.
 *
 * @throws
 * The function throws an error if there is a timeout removing the data
 * from the parent's application storage.
 *
 * @returns A promise that resolves to `void` once the data has
 * been successfully removed.
 */
export async function storageRemove(key: string): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await sendSdkRequest(parentApp, StorageAction.Remove, { key });
  } else {
    storage.removeItem(key);
  }

  return Promise.resolve();
}

/**
 * The function allows to clear all data from the SDK storage.
 *
 * @throws
 * The function throws an error if there is a timeout clearing the parent's
 * application storage.
 *
 * @returns A promise that resolves to `void` once the data has been
 * successfully cleared.
 */
export async function storageClear(): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await sendSdkRequest(parentApp, StorageAction.Clear);
  } else {
    storage.clear();
  }

  return Promise.resolve();
}

registerEmbedHook(listenStorageMessages);

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { EventEmitter } from 'events';

/**
 * Android SDK registers the following functions:
 *   - calls `window.monterosaSdk.receiveMessage()` if wants to send a message
 *   - registers `window.monterosaSdk.postMessage()` to receive a message
 * iOS SDK:
 *   - calls `window.monterosaSdk.receiveMessage()` if wants to send a message
 *   - registers `window.webkit.messageHandlers.monterosaSdk.postMessage()` to receive a message
 */

type GlobalMonterosaSdk = {
  initialised: boolean;
  emitter: EventEmitter;
  // send message to Android
  postMessage?: (message: string) => void;
  // receive a message from Android or iOS
  receiveMessage: (message: Message) => void;
};

type GlobalWebkitType = {
  messageHandlers?: {
    monterosaSdk: {
      // post message to iOS
      postMessage: (message: string) => void;
    };
  };
};

declare global {
  /**
   * Declare variables in globalThis. This solution only works by declaring
   * variables with `var`: https://stackoverflow.com/a/69429093
   */

  // eslint-disable-next-line
  var monterosaSdk: GlobalMonterosaSdk | undefined;

  // eslint-disable-next-line
  var webkit: GlobalWebkitType | undefined;

  interface Window {
    monterosaSdk?: GlobalWebkitType;
    webkit?: GlobalWebkitType;
  }
}

/**
 * The Payload type is a type alias that represents a key-value hash object
 * in which the keys are strings and the values can be of any type.
 * This is a flexible way to define the content of the message being sent,
 * as the keys can be customized to match the specific needs of the application,
 * and the values can be any valid JavaScript type.
 */
export type Payload = { [string: string]: any };

/**
 * The Message interface is used to define the structure of a message that
 * is passed between two applications via a communication bridge.
 */
export interface Message {
  /**
   * Id of the message. Its autogenerated by the communication bridge
   */
  id: string;
  /**
   * Id of the message this message responds to.
   */
  respondingTo: string | null;
  /**
   * The source of message. Used to distinguish Monterosa SDK messages from other
   * broadcasted messages. In the future can be used for a more fine grade targeting
   */
  sourceName: Source | string;
  /**
   * The current timestamp in milliseconds
   */
  timestamp: number;
  /**
   * Defines the message action
   */
  action: Action | string;
  /**
   * Hash of key-value pairs. Values constrained by json format:
   * four primitive types (strings, numbers, booleans,
   * and null) and two structured types (objects and arrays).
   */
  payload: Payload;
  /**
   * Used to identify if the message belongs to the communication
   * bridge established between parent and child
   */
  bridgeId: string;
  /**
   * Message protocol version
   */
  version: string;
}

/**
 * @internal
 */
export interface Bridge extends EventEmitter {
  id: string;
  iFrameId: string;
  iFrameSelector: string;
  childIFrame: HTMLIFrameElement | null;
  send: (
    action: string,
    payload?: Payload,
    sourceName?: Source,
    id?: string,
  ) => Message;
  request: (
    action: string,
    payload?: Payload,
    timeout?: number,
    sourceName?: Source,
  ) => Promise<Message>;
}

/**
 * @internal
 */
export interface Bridged {
  bridge: Bridge;
}

/**
 * A list of possible actions in communications between
 * parent application and child Experience
 *
 * @internal
 */
export enum Action {
  /**
   * Notifies that communication channel is ready and
   * messages can be {@link sendSdkMessage | sent}
   */
  OnReady = 'onBridgeReady',
  /**
   * Notifies that intrisic size of child Experience has changed
   */
  OnResize = 'onIntrinsicSizeChanged',
  /**
   * Notifies that UI of child Experience is loaded.
   * When this action is fired the loader will be hidden
   */
  OnUILoaded = 'onUILoaded',
  /**
   * Notifies child Experience about the request to load more data
   */
  OnMoreDataRequested = 'onMoreDataRequested',
}

/**
 * @internal
 */
export enum Source {
  Sdk = 'sdk',
  User = 'user',
}

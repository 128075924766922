/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Unsubscribe, subscribe } from '@monterosa-sdk/util';

import { Bridge, Bridged, Message, Source, Payload } from './public-types';

import { QueryParam } from '../../types';

import Config from './config';
import BridgeImpl from './bridge_impl';

let parentBridge: Bridge;

const bridges: Map<string, BridgeImpl> = new Map();

/**
 * Checks if Experience has a parent bridge based on the presence of a query parameter.
 *
 * @returns True if Experience has a parent bridge, false otherwise.
 *
 * @internal
 */
export function hasParentBridge(): boolean {
  const url = new URL(window.location.href);

  return url.searchParams.has(QueryParam.BridgeId);
}

/**
 * @internal
 */
export function getBridge(id: string): BridgeImpl {
  if (bridges.has(id)) {
    return bridges.get(id) as BridgeImpl;
  }

  const bridge = new BridgeImpl();

  bridges.set(id, bridge);

  return bridge;
}

/**
 * @internal
 */
export function getParentBridge(): Bridge | null {
  if (typeof window === 'undefined') {
    return null;
  }

  if (parentBridge !== undefined) {
    return parentBridge;
  }

  const url = new URL(window.location.href);

  const bridgeId = url.searchParams.get(QueryParam.BridgeId);

  if (bridgeId === null) {
    return null;
  }

  parentBridge = new BridgeImpl(bridgeId);

  return parentBridge;
}

/**
 * @internal
 */
export function sendSdkMessage(
  bridged: Bridged,
  action: string,
  payload: Payload = {},
) {
  return bridged.bridge.send(action, payload, Source.Sdk);
}

/**
 * This function allows to send a simple message with action name and payload to
 * a recipient which can be either parent application (from Experience to parent
 * application) or child Experience (from parent page to Experience).
 *
 * @remarks
 * Usage example in parent application:
 *
 * @example
 * ```typescript
 * const experience = getExperience();
 *
 * // message to Experience can be sent only when its ready
 * onUILoaded(experience, async () => {
 *   sendMessage(
 *     experience,
 *     'my_action',
 *     { key: 'value' }
 *   );
 * });
 *
 * embed(experience);
 * ```
 *
 * Usage example in child Experience:
 *
 * @example
 * ```typescript
 * const parentApp = getParentApplication();
 *
 * if (parentApp !== null) {
 *   sendMessage(
 *     parentApp,
 *     'my_action',
 *     { key: 'value' }
 *   );
 * }
 * ```
 *
 * @param bridged - Instance of either {@link ParentApplication} or {@link Experience}
 * @param action - Arbitrary action name that defines purpose of the message
 * @param payload - A key-value object that is sent in a message
 * @returns Returns {@link Message | message}
 */
export function sendMessage(
  bridged: Bridged,
  action: string,
  payload: Payload = {},
) {
  return bridged.bridge.send(action, payload, Source.User);
}

/**
 * @internal
 */
export function sendSdkRequest(
  bridged: Bridged,
  action: string,
  payload: Payload = {},
  timeout = Config.requestTimeout,
) {
  return bridged.bridge.request(action, payload, timeout, Source.Sdk);
}

/**
 * This function allows to send a request with action name and payload to
 * a recipient which can be either parent application (from Experience to parent
 * application) or child Experience (from parent page to Experience). It is similar
 * to {@link sendMessage} with only one difference is that it returns a Promise which
 * resolves if the recipient response with {@link respondToMessage}. Otherwise it
 * will rejects after a certain timeout.
 *
 * @remarks
 * Usage example in parent application:
 *
 * @example
 * ```typescript
 * const experience = getExperience();
 *
 * // request to Experience can be sent only when its ready
 * onUILoaded(experience, async () => {
 *   const response = await sendRequest(
 *     experience,
 *     'my_action',
 *     { key: 'value' }
 *   );
 *
 *   console.log(response);
 * });
 *
 * embed(experience);
 * ```
 *
 * Usage example in child Experience:
 *
 * @example
 * ```typescript
 * const parentApp = getParentApplication();
 *
 * // parent application can be null if Experience is running stand alone
 * if (parentApp !== null) {
 *   const response = await sendRequest(
 *     parentApp,
 *     'my_action',
 *     { key: 'value' }
 *   );
 *
 *   console.log(response);
 * }
 * ```
 *
 * @param bridged - Instance of either {@link ParentApplication} or {@link Experience}
 * @param action - Arbitrary action name that defines purpose of the message
 * @param payload - A key-value object that is sent as in request
 * @param timeout - Configurable request timeout, if there is no response by
 * the end of this timeout then returned Promise will be rejected.
 */
export function sendRequest(
  bridged: Bridged,
  action: string,
  payload: Payload = {},
  timeout = Config.requestTimeout,
) {
  return bridged.bridge.request(action, payload, timeout, Source.User);
}

/**
 * @internal
 */
export function respondToSdkMessage(
  bridged: Bridged,
  message: Message,
  payload: Payload = {},
) {
  bridged.bridge.send(message.action, payload, Source.Sdk, message.id);
}

/**
 * Respond to a received message. It is used to reply to a
 * {@link sendRequest | user request}.
 *
 * @param bridged - Instance of either {@link ParentApplication} or {@link Experience}
 * @param message - {@link Message | Message} to respond to
 * @param payload - A key-value object that is sent as in response
 */
export function respondToMessage(
  bridged: Bridged,
  message: Message,
  payload: Payload = {},
) {
  bridged.bridge.send(message.action, payload, Source.User, message.id);
}

function onMessageFunc(
  bridged: Bridged,
  source: Source,
  callback: (message: Message) => void,
) {
  return subscribe(bridged.bridge, 'message', (message: Message) => {
    if (message.sourceName === source) {
      callback(message);
    }
  });
}

/**
 * @internal
 */
export function onSdkMessage(
  bridged: Bridged,
  callback: (message: Message) => void,
): Unsubscribe {
  return onMessageFunc(bridged, Source.Sdk, callback);
}

/**
 * Adds an observer for when user message is received
 *
 * @param bridged - Instance of either {@link ParentApplication} or {@link Experience}
 * @param callback - The callback that is triggered when user message is received
 * @returns The unsubscribe function. When it's called,
 * the observer will be removed and user messages will no longer be received
 */
export function onMessage(
  bridged: Bridged,
  callback: (message: Message) => void,
): Unsubscribe {
  return onMessageFunc(bridged, Source.User, callback);
}

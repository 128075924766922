/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';
import { getGlobal } from '@monterosa-sdk/util';

import './sideeffects';

import { Message, Bridge, Payload, Source, Action } from './public-types';
import {
  IFRAME_ID_PREFIX,
  VERSION,
  IS_IOS,
  IS_WEB,
  IS_ANDROID,
} from './constants';
import Config from './config';

import { logger } from '../../logger';

const globals = getGlobal();

/**
 * @internal
 */
class BridgeImpl extends EventEmitter implements Bridge {
  private recipientReady: boolean = false;
  private messagesQueue: Message[] = [];

  constructor(public id: string = uuidv4()) {
    super();

    globals.monterosaSdk!.emitter.on('message', this.handleMessage.bind(this));
  }

  static isMessage(message: Message | any): message is Message {
    return (
      message instanceof Object &&
      Object.prototype.hasOwnProperty.call(message, 'bridgeId') &&
      Object.prototype.hasOwnProperty.call(message, 'action')
    );
  }

  get iFrameId(): string {
    return `${IFRAME_ID_PREFIX}-${this.id}`;
  }

  get iFrameSelector(): string {
    return `iframe#${this.iFrameId}`;
  }

  get childIFrame(): HTMLIFrameElement | null {
    return document.querySelector(this.iFrameSelector);
  }

  private handleMessage(message: Message) {
    const { id, bridgeId, action, respondingTo } = message;

    if (bridgeId !== this.id) {
      return;
    }

    logger.log(
      `Received a ${respondingTo === null ? 'message' : 'response'}`,
      message,
    );

    if (action === Action.OnReady) {
      this.recipientReady = true;

      if (respondingTo === null) {
        this.send(Action.OnReady, {}, Source.Sdk, id);
      }

      while (this.messagesQueue.length > 0) {
        this.postMessage(this.messagesQueue.shift()!);
      }
    }

    this.emit('message', message);
  }

  private createMessage(
    action: string,
    payload: Payload,
    sourceName: Source,
    respondingTo: string | null = null,
  ): Message {
    return {
      id: uuidv4(),
      respondingTo,
      action,
      sourceName,

      bridgeId: this.id,

      payload,
      version: VERSION,
      timestamp: Date.now(),
    };
  }

  postMessage(message: Message) {
    if (!this.recipientReady && message.action !== Action.OnReady) {
      this.messagesQueue.push(message);

      return;
    }

    const json = JSON.stringify(message);

    if (IS_IOS) {
      globals.webkit?.messageHandlers?.monterosaSdk.postMessage(json);
    }

    if (IS_ANDROID) {
      if (globals.monterosaSdk?.postMessage) {
        globals.monterosaSdk.postMessage(json);
      }
    }

    if (IS_WEB) {
      globals.parent.postMessage(json, '*');
    }

    if (this.childIFrame) {
      this.childIFrame.contentWindow?.postMessage(json, '*');
    }
  }

  send(
    action: string,
    payload: Payload = {},
    sourceName = Source.Sdk,
    respondingTo?: string,
  ): Message {
    const message = this.createMessage(
      action,
      payload,
      sourceName,
      respondingTo,
    );

    logger.log(
      `Sending a ${message.respondingTo === null ? 'message' : 'response'}`,
      message,
    );

    this.postMessage(message);

    return message;
  }

  async request(
    action: string,
    payload: Payload = {},
    timeout: number = Config.requestTimeout,
    sourceName = Source.Sdk,
  ): Promise<Message> {
    let timeoutRef: ReturnType<typeof setTimeout>;
    let handler: (message: Message) => void;

    const message = this.createMessage(action, payload, sourceName);

    logger.log('Sending a request', message);

    /**
     * Start the timeout, when it finishes it should reject Promise.race below
     */
    const countdown: Promise<any> = new Promise((_, reject) => {
      timeoutRef = setTimeout(reject, timeout);
    });

    /**
     * Start the request and wait for the message with the respondingTo
     * equal to message id we sent
     */
    const request: Promise<Message> = new Promise((resolve) => {
      handler = (responseMessage: Message) => {
        if (responseMessage.respondingTo === message.id) {
          resolve(responseMessage);
        }
      };

      globals.monterosaSdk!.emitter.on('message', handler);

      this.postMessage(message);
    });

    /**
     * Start race between timeout and request
     *   - if timeout wins the promise will be rejected
     *   - if request wins then Message will be resolved
     */

    return (
      Promise.race([countdown, request])
        /**
         * As the matter of clean up we need to clear timeout id
         * and unsubscribe from the message event
         */
        .finally(() => {
          clearTimeout(timeoutRef);
          globals.monterosaSdk!.emitter.off('message', handler);
        })
    );
  }
}

export default BridgeImpl;

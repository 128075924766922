/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { createError } from '@monterosa-sdk/util';

import { BridgeError, BridgeErrorMessages } from './types';

/**
 * @internal
 */
const Config = {
  requestTimeout: 20000,
};

/**
 * Sets a new timeout value for requests (default is 20_000 mseconds).
 *
 * This function updates the request timeout in the application's configuration.
 * It ensures that the new timeout value is a positive number, and throws
 * an error if the value is non-positive.
 *
 * @param newTimeout - The new timeout value in milliseconds. Must be
 *   a positive number.
 *
 * @throws
 * Throws {@link BridgeError | BridgeError.InvalidRequestTimeoutError}
 * if `newTimeout` is less than or equal to 0.
 *
 * @example
 * ```javascript
 * // Set the request timeout to 3000 milliseconds
 * setRequestTimeout(3000);
 * ```
 */
export function setRequestTimeout(newTimeout: number) {
  if (newTimeout <= 0) {
    throw createError(
      BridgeError.InvalidRequestTimeoutError,
      BridgeErrorMessages,
    );
  }

  Config.requestTimeout = newTimeout;
}

export default Config;

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

/**
 * Defines a set of error codes that may be encountered when using the
 * Launcher Kit bridge
 *
 * @example
 * ```javascript
 * try {
 *   // some code that uses the LauncherKit's bridge
 * } catch (err) {
 *   if (err.code === BridgeError.InvalidRequestTimeoutError) {
 *     // handle invalid request timeout error
 *   } else {
 *     // handle other error types
 *   }
 * }
 * ```
 *
 * @remarks
 * - The `BridgeError` enum provides a convenient way to handle errors
 *   encountered when using the `LauncherKit` module. By checking the code
 *   property of the caught error against the values of the enum, the error
 *   type can be determined and appropriate action taken.
 *
 * - The `BridgeError` enum is not intended to be instantiated or extended.
 */
export enum BridgeError {
  /**
   * Indicates an error occurred due to an invalid timeout value being provided.
   * This error is thrown when the specified timeout is not a positive number.
   */
  InvalidRequestTimeoutError = 'invalid_request_timeout_error',
}

export const BridgeErrorMessages = {
  [BridgeError.InvalidRequestTimeoutError]: () =>
    'Request timeout must be greater than 0',
};

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Message } from './public-types';

export function isMessage(message: Message | any): message is Message {
  return (
    message instanceof Object &&
    Object.prototype.hasOwnProperty.call(message, 'bridgeId')
  );
}

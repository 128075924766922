/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { delay } from '@monterosa-sdk/util';

import { LOADER_ANIMATION_DURATION, DEFAULT_HEIGHT } from './constants';

/* eslint-disable */
type CSSProperties = keyof Omit<
  CSSStyleDeclaration,
  | typeof Symbol.iterator
  | number
  | 'length'
  | 'parentRule'
  | 'getPropertyPriority'
  | 'getPropertyValue'
  | 'item'
  | 'removeProperty'
  | 'setProperty'
>;
/* eslint-enable  */

function getLoadingTemplate(): string {
  return `
    <div>
      <style type="text/css">
        .__mic-loader {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 9;
          box-sizing: border-box;
          text-align: center;
          background: #fff;
        }

        .__mic-loader__container {
          padding: 20px 0;
          gap: 20px;
          min-height: ${DEFAULT_HEIGHT}px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .__mic-loader__spinner {
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #0b0f1c;
          color: #0b0f1c;
          animation: mic-loader__animation 1s infinite linear alternate;
          animation-delay: 0.5s;          
        }

        .__mic-loader__spinner::before,
        .__mic-loader__spinner::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 0;
        }

        .__mic-loader__spinner::before {
          left: -15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #0b0f1c;
          color: #0b0f1c;
          animation: mic-loader__animation 1s infinite alternate;
          animation-delay: 0s;
        }

        .__mic-loader__spinner::after {
          left: 15px;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #0b0f1c;
          color: #0b0f1c;
          animation: mic-loader__animation 1s infinite alternate;
          animation-delay: 1s;
        }

        @keyframes mic-loader__animation {
          0% {
            background-color: #0b0f1c;
          }
          50%, 100% {
            background-color: rgba(11, 15, 28, 0.2);
          }
        }
      </style>
      <div class="__mic-loader">
        <div class="__mic-loader__container">
          <div class="__mic-loader__spinner"></div>
        </div>
      </div>
    </div>
  `;
}

export function embed(
  container: HTMLElement,
  loadingTemplate: () => string = getLoadingTemplate,
): HTMLElement {
  /**
   * Return existing element if it exists
   */
  let loaderElement: HTMLElement | null =
    container.querySelector('[data-role=loader]');

  if (loaderElement !== null) {
    return loaderElement;
  }

  /**
   * Stash and apply some custom styles to container element
   */
  stashStyles(container, ['minHeight', 'position']);

  container.style.minHeight = `${DEFAULT_HEIGHT}px`;

  /**
   * Inject loader element built from the html markup template
   */
  const templateElement = document.createElement('template');
  templateElement.innerHTML = loadingTemplate().trim();

  loaderElement = container.appendChild(
    templateElement.content.firstElementChild as Element,
  ) as HTMLElement;

  /**
   * Setting custom data attribute to be able to distinguish element along
   * other injected html elements if any
   */
  loaderElement.setAttribute('data-role', 'loader');

  return loaderElement;
}

export function unmount(container: HTMLElement): void {
  const loaderElement = container.querySelector('[data-role=loader]');

  if (loaderElement === null) {
    return;
  }

  loaderElement.parentElement?.removeChild(loaderElement);

  unstashStyles(container);
}

export async function show(
  container: HTMLElement,
  loadingTemplate: () => string = getLoadingTemplate,
): Promise<void> {
  const loaderElement = embed(container, loadingTemplate);

  const { position } = getComputedStyle(container);

  /**
   * In order to properly position the loader,
   * the container's position should be anything but 'static'.
   */
  if (position === 'static') {
    container.style.position = 'relative';
  }

  /**
   * Setting styles to the loader for a nice fade in animation
   */
  loaderElement.style.opacity = '0';

  /**
   * Kicking fade animation in.
   */
  setTimeout(() => {
    loaderElement.style.transition = `all ${LOADER_ANIMATION_DURATION}ms`;
    loaderElement.style.opacity = '1';
  }, 0);

  /**
   * Wait until the animation is finished. We do not use "transitionend" event
   * here as it will just make the code more bloated and unnecessary complex.
   */
  await delay(LOADER_ANIMATION_DURATION);
}

export async function hide(container: HTMLElement): Promise<void> {
  const loaderElement: HTMLElement | null =
    container.querySelector('[data-role=loader]');

  if (loaderElement === null) {
    return;
  }

  /**
   * Kicking fade out animation.
   */
  setTimeout(() => {
    loaderElement.style.opacity = '0';
  }, 0);

  /**
   * Wait until the animation is finished. We do not use "transitionend" event
   * here as it will just make the code more bloated and unnecessary complex.
   */
  await delay(LOADER_ANIMATION_DURATION);

  unstashStyles(container);
  unmount(container);
}

function stashStyles(element: HTMLElement, props: CSSProperties[]) {
  const styles = getComputedStyle(element);

  const stash = props
    .map((prop) => `${String(prop)}=${styles[prop]}`)
    .join(';');

  element.setAttribute('data-stash', stash);
}

function unstashStyles(element: HTMLElement) {
  const stash = element.getAttribute('data-stash');

  if (stash === null) {
    return;
  }

  const attributes: [attr: CSSProperties, value: string][] = stash
    .split(';')
    .map((attr) => attr.split('=') as [attr: CSSProperties, value: string]);

  for (const [attr, value] of attributes) {
    element.style[attr] = value;
  }

  element.removeAttribute('data-stash');
}

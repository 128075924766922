/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

export const RESIZE_THROTTLE_TIMEOUT = 25;

/**
 * Duration of the loader fade IN/OUT animation
 */
export const LOADER_ANIMATION_DURATION = 750;

/**
 * Final cut off timeout for the loader after which it will be hidden even
 * though Experience UI may ne not yet ready
 */
export const LOADER_MAX_TIMEOUT = 5_000;

/**
 * Minimum timeout during which the loader still visible even though Experience
 * UI may be ready
 *
 * (!) At the moment its value is 0 as loader has a fade in effect what already
 * adds a small delay before Experience is injected on the page
 */
export const LOADER_MIN_TIMEOUT = 0;

export const DEFAULT_HEIGHT = 250;

/**
 * @license
 * @monterosa-sdk/launcher-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { ParentApplication } from './types';

import ParentApplicationImpl from './parent_application_impl';

import { getParentBridge } from './utils/bridge';

let parentApplication: ParentApplication;

/**
 * Returns instance of parent application.
 *
 * @returns Returns {@link ParentApplication}
 * if an Experience resides within the parent application, and it was embedded
 * using {@link embed} function. Otherwise it returns `null`.
 */
export function getParentApplication(): ParentApplication | null {
  if (parentApplication !== undefined) {
    return parentApplication;
  }

  const parentBridge = getParentBridge();

  if (parentBridge === null) {
    return null;
  }

  parentApplication = new ParentApplicationImpl(parentBridge);

  return parentApplication;
}
